.r1jd9klj{position:relative;}
.c1agjvi3{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-top:3px;}
.eo8e82c{-webkit-transition-property:background-color,border-color,color,fill,stroke,opacity,box-shadow,-webkit-transform;-webkit-transition-property:background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;transition-property:background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;-webkit-transition-duration:200ms;transition-duration:200ms;-webkit-transition-timing-function:cubic-bezier(0,0,0.2,1);transition-timing-function:cubic-bezier(0,0,0.2,1);}
.l9j57f1{-webkit-transition-property:background-color,border-color,color,fill,stroke,opacity,box-shadow,-webkit-transform;-webkit-transition-property:background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;transition-property:background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;-webkit-transition-duration:150ms;transition-duration:150ms;-webkit-transition-timing-function:cubic-bezier(0.4,0,1,1);transition-timing-function:cubic-bezier(0.4,0,1,1);}
.e1k8el6i{-webkit-transform:translateY(0.25rem);-ms-transform:translateY(0.25rem);transform:translateY(0.25rem);opacity:0;}
.eaaduhh{-webkit-transform:translateY(0);-ms-transform:translateY(0);transform:translateY(0);opacity:1;}
.p16wt2f3{position:absolute;left:50%;z-index:10;-webkit-transform:translateX(-50%);-ms-transform:translateX(-50%);transform:translateX(-50%);}
.p1tch44b{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:6px;padding:0 var(--element-padding-xxs);cursor:pointer;background-color:transparent;border:0;color:white;outline:none;}
.npkebcv{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-left:1rem;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.dqbomxx{padding:8px 0px;}.dqbomxx nav{background:var(--gsu-primary10);color:var(--gsu-primary);border-radius:var(--border-radius-md);-webkit-animation:navIn-dqbomxx 0.3s ease-in-out 0.3s;animation:navIn-dqbomxx 0.3s ease-in-out 0.3s;-webkit-animation-fill-mode:both;animation-fill-mode:both;overflow:hidden;}.dqbomxx ul{list-style:none;padding:0;margin:0;}.dqbomxx ul li{width:86px;}.dqbomxx ul li:hover{background:var(--gsu-primary20);cursor:pointer;}.dqbomxx ul li span{padding:0.5rem 1rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex:1 1;-ms-flex:1 1;flex:1 1;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-weight:var(--font-weight-semi-bold);font-size:var(--font-size-xxs);}.dqbomxx hr{border:none;height:1px;background:var(--gsu-dark-grey-hover);margin:var(--element-spacing) 0;}
